// i18next-extract-mark-ns-start documents

import React, { useEffect, useState, useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'
import RelatedDocuments from 'src/components/document/RelatedDocuments'
import config from 'src/../tailwind.config'
import Select from 'src/components/ui/Select'
import { ISelectOption } from 'src/components/ui/SelectOption'
import filterBySelectOption, {
  findDocumentTypeById,
  findYearById,
  findLanguageById,
  setUrlParam,
  isSetParamInUrl,
  groupDocumentsByType,
  sortDocumentGroups,
} from 'src/helpers/documents'

interface IDocumentType {
  label: string
  position: number
  optionId:
    | 'laws-of-the-game'
    | 'law-changes'
    | 'circulars'
    | 'meeting-agendas-minutes'
    | 'trials'
    | 'IFAB STATUTES'
}
export interface IDocument {
  id: string
  name: string
  slug: string
  externalLink: boolean
  externalLinkUrl: string
  asset: {
    url: string
  }
  year: {
    label: string
    optionId: string
  }
  language: {
    label: string
    optionId: string
  }
  documentType: IDocumentType
}

export interface IDocumentGroup {
  documentType: IDocumentType
  documents: Array<IDocument>
}

const IndexPage = ({ pageContext: { data } }) => {
  const { t } = useTranslation('documents')
  const [selectedDocumentType, setSelectedDocumentType] = useState(
    null as null | ISelectOption
  )
  const [selectedYear, setSelectedYear] = useState(null as null | ISelectOption)
  const [selectedLanguage, setSelectedLanguage] = useState(
    null as null | ISelectOption
  )
  const lngContext = useContext(I18nextContext)

  const handleDocumentTypeSelect = (option: ISelectOption) => {
    setSelectedDocumentType(option)
    setUrlParam('documentType', option.optionId)
  }
  const handleYearSelect = (option: ISelectOption) => {
    setSelectedYear(option)
    setUrlParam('years', option.optionId)
  }
  const handleLanguageSelect = (option: ISelectOption) => {
    setSelectedLanguage(option)
    setUrlParam('language', option.optionId)
  }

  const getFilteredDocuments = () => {
    const filteredByDocumentType = filterBySelectOption(
      data.documents.nodes,
      selectedDocumentType,
      'documentType'
    )
    const filteredByLanguage = filterBySelectOption(
      filteredByDocumentType,
      selectedLanguage,
      'language'
    )

    return filterBySelectOption(
      filteredByLanguage,
      selectedYear,
      'years'
    ) as Array<IDocument>
  }

  const getYearsForSelect = () => data.yearTypes.nodes

  const setFiltersFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    setSelectedDocumentType(
      findDocumentTypeById(params.documentType, data.documentTypes.nodes)
    )
    setSelectedYear(findYearById(params.years, getYearsForSelect()))
    setSelectedLanguage(
      findLanguageById(params.language, data.languageTypes.nodes)
    )
  }

  const autoSelectCurrentLanguage = () => {
    const currentLanguage = lngContext.language

    const defaultLanguage = data.languageTypes.nodes.find(
      (lng) => lng.optionId === currentLanguage
    )

    handleLanguageSelect(defaultLanguage)
  }

  const autoSelectCurrentYear = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    if (params.years) {
      return
    }

    const currentYear = new Date().getFullYear().toString()
    const defaultYear = data.yearTypes.nodes.find(
      (year) => year.optionId === currentYear
    )

    if (!defaultYear) {
      return
    }

    handleYearSelect(defaultYear)
  }

  useEffect(() => {
    autoSelectCurrentLanguage()
    autoSelectCurrentYear()
    setFiltersFromUrl()
  }, [])

  const getDocumentGroups = () => {
    const filteredDocuments = getFilteredDocuments()
    const documentGroups = groupDocumentsByType(filteredDocuments)
    const sortedDocumentGroups = sortDocumentGroups(documentGroups)

    return sortedDocumentGroups.map((documentGroup) => (
      <div key={documentGroup.documentType?.optionId} className="mb-8">
        <div className="flex items-center mb-8">
          <h4 className="text-xl font-bold">
            {documentGroup.documentType?.label}
          </h4>
        </div>
        <RelatedDocuments
          color={config.theme.extend.colors.deepDarkBlue}
          items={documentGroup.documents}
        />
      </div>
    ))
  }

  return (
    <>
      <Helmet>
        <title>{t('Documents')}</title>
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <ContentPageContainer>
            <PageTitle>
              <span>{t('Documents')}</span>
            </PageTitle>
            <Section>
              <div className="mb-12 md:mt-12 flex flex-wrap md:flex-nowrap justify-between">
                <Select
                  options={data.documentTypes.nodes}
                  onSelect={handleDocumentTypeSelect}
                  selectedItem={selectedDocumentType}
                  className="w-full md:w-1/3 md:mr-0.5 md:mt-4"
                  placeholder={t('ALL DOCUMENT TYPES')}
                  isSelectedAllAsDefault={!isSetParamInUrl('documentType')}
                />
                <Select
                  options={getYearsForSelect()}
                  onSelect={handleYearSelect}
                  selectedItem={selectedYear}
                  className="w-full md:w-1/3 md:mr-0.5 mt-4"
                  placeholder={t('ALL YEARS')}
                />
                <Select
                  options={data.languageTypes.nodes}
                  selectedItem={selectedLanguage}
                  onSelect={handleLanguageSelect}
                  className="w-full md:w-1/3 mt-4"
                  placeholder={t('ALL LANGUAGES')}
                />
              </div>
              {getDocumentGroups()}
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default IndexPage
